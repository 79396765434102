#logo {
  position: relative;
  top: 18px;
  left: 10px;
  float: left;
}

#logo-default {
  position: relative;
  top: 15px;
  left: 10px;
  float: left;
  display: flex;
  cursor: pointer;
}

#logo-default-img img {
  margin-top: 1px;
}
#logo-default-firm {
  font-family: 'Audiowide' !important;
  font-size: 27px;
  letter-spacing: 7px;
  margin-top: 1px;
  margin-left: 2px;
  margin-right: 20px;
  padding: 0;
}

#logo img {
  width: 130px;
  cursor: pointer;
}

#title {
  float: left;
  position: relative;
  left: 15px;
  top: 28px;
  width: 60%;
}

#actionMenu > .btn-group-md {
  width: 100px !important;
  position: relative;
  top: -4px;
}

button:focus {
  outline: none !important;
}

#menus {
  padding-right: 20px;
  white-space: nowrap !important;
  height: 80px;

  width: 100%;
}

body {
  outline: none !important;
  margin: 0;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.condensed-header {
  border-top: solid 1px var(--border-color);
  background-color: white;
  overflow: hidden;
  transition: max-height 200ms ease;
  height: 38px;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
}

.header-client-info {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.header-client-content {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.header-client-content > p {
  font-weight: bold;
  color: var(--main-contrast-color);
}

.header-client-content > span {
  margin-left: 3px;
}

.sign-out-wrapper {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
  margin-right: 20px;
}
.sign-out-icon {
  font-size: 30px;
  align-items: center;
  cursor: pointer;
  color: rgb(158, 158, 158);
  color: var(--main-primary-button-bg-color, var(--main-contrast-color));
}
.contact-us-icon {
  font-size: 25px;
  align-items: center;
  cursor: pointer;
  color: rgb(158, 158, 158);
  color: var(--main-primary-button-bg-color, var(--main-contrast-color));
  margin-right: 20px;
}

.enroll-wrapper,
.myenrollments-wrapper {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  background-color: #fff;
  width: 95%;
  display: inline-block;
  text-align: center;
  min-height: 80vh;
  overflow: auto;
}

.confirm-alert-confirm {
  margin-left: 5px;
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.confirm-alert-cancel {
  margin-left: 17px;
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}
